import React, { useState, useEffect } from 'react';

let textData = [
     "App",
     "Game",
     "Website",
     "Blog",
     "Mobile App",
     "NodeJS Application",
     "React Application",
     "SQL Database",
     "MongoDB",
     "Productivity Tool",
     "Business Application"
];
let textDataIndex = 0;

const Typewriter = ({ delay }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
     if (currentIndex < textData[textDataIndex].length) {
       const timeout = setTimeout(() => {
         setCurrentText(prevText => prevText + textData[textDataIndex][currentIndex]);
         setCurrentIndex(prevIndex => prevIndex + 1);
       }, delay);
       

       return () => clearTimeout(timeout);
     }
     else{
          const timeout = setTimeout(() => {
               setCurrentIndex(0);
               setCurrentText("");
               textDataIndex++;
               if(textDataIndex >= textData.length){
                    textDataIndex = 0;
               }
          }, delay * 10);
          
   
          return () => clearTimeout(timeout);
     }
   }, [currentIndex, delay, textData[textDataIndex]]);

  return <span className="typewriter-text">{currentText}</span>;
};

export default Typewriter;