import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    Button,
    Card, CardImg, CardBody, CardTitle, CardText
} from 'reactstrap';
import './App.css';
import Typewriter from "./Components/Typewriter";
import office from './images/office.jpg'; // gives image path


function ExpandNavLink(id) {
    let el = document.getElementById(id);
    if (el != null)
        el.style.display = "inline";
}
function CollapseNavLink(id) {
    let el = document.getElementById(id);
    if (el != null)
        el.style.display = "none";
}
class App extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div className="App">
                <Navbar color="dark" dark expand="md">
                    <NavbarBrand href="/"><h1>Mendoza Creative Projects</h1></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav navbar>
                            <NavItem>
                                <NavLink href="#about"><h3 onMouseLeave={() => CollapseNavLink("navLink1")} onMouseEnter={() => ExpandNavLink("navLink1")}><i class="fa-solid fa-user-tie"></i> <span class="hidden" id="navLink1">About Me</span></h3></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#services" onMouseLeave={() => CollapseNavLink("navLink2")} onMouseEnter={() => ExpandNavLink("navLink2")}><h3><i class="fa-solid fa-code"></i> <span class="hidden" id="navLink2">Services</span></h3></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#resume" onMouseLeave={() => CollapseNavLink("navLink3")} onMouseEnter={() => ExpandNavLink("navLink3")}><h3><i class="fa-regular fa-file"></i> <span class="hidden" id="navLink3">Resume</span></h3></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#portfolio" onMouseLeave={() => CollapseNavLink("navLink4")} onMouseEnter={() => ExpandNavLink("navLink4")}><h3><i class="fa-solid fa-briefcase"></i> <span class="hidden" id="navLink4">Portfolio</span></h3></NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink href="#testimonials" onMouseLeave={()=>CollapseNavLink("navLink5")} onMouseEnter={()=>ExpandNavLink("navLink5")}><h3><i class="fa-regular fa-star"></i> <span class="hidden" id="navLink5">Testimonials</span></h3></NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink href="#contact" onMouseLeave={() => CollapseNavLink("navLink6")} onMouseEnter={() => ExpandNavLink("navLink6")}><h3><i class="fa-regular fa-envelope"></i> <span class="hidden" id="navLink6">Contact</span></h3></NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
                <Container fluid className="text-center">
                    <Row className="welcome" dark>
                        <div className='welcome-image'></div>
                        <div className='welcome-text my-5'>
                            <h1>Mendoza Creative Projects, At Your Service</h1>
                            {/* <p>
                                        <Button
                                            tag="a"
                                            color="success"
                                            size="large"
                                            href="http://reactstrap.github.io"
                                            target="_blank"
                                        >
                                            View Reactstrap Docs
                                        </Button>
                                    </p> */}
                            <Row className="my-5">
                                <Col className='text-end'>
                                    <h2>Coding Your</h2>
                                </Col>
                                <Col className='text-start'>
                                    <h2><Typewriter text="React App" delay={100} /></h2>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
                <Container className="py-5">
                    <Row id="about" dark className='p-3'>
                        <Col>
                            <h1>About Me</h1>
                            <h2>The Digital Artisan</h2>
                            <p>My journey began over a decade ago. Inspired by the idea to make video games, I set off researching how to program them. I attempted Visual Basic and then moved on to C#.</p>
                            <p>In my college years, I picked up Java, learned the fundamentals for programming languages and data structures, then moved on to working with web development technologies, databases, and game development.</p>
                            <p>Now I primarily freelance for various companies, organizations, and people.</p>
                            <p>
                                My past coworkers and peers would describe me as a problem-solver, an adept learner, and a natural teacher.
                            </p>
                            <p>
                                In my freetime, I dabble in illustrative arts and writing. As you will see, I always find a way to stay productive.
                            </p>
                            <p>
                                If you have an idea, we can build it together.
                            </p>
                        </Col>
                    </Row>
                    </Container>
                    <hr className='m-5'></hr>
                <Container>
                    <Row id="services" dark className='p-3'>
                        <Col>
                            <h1>Services</h1>
                            <h2>Getting the Job Done <i class="fa-regular fa-square-check"></i></h2>
                            <p>
                                I can primarily be inquired at <a href="https://www.fiverr.com/tonyomendoza">Fiverr</a>. However, I do have an email which I attached to the bottom of this website. Although I have a limited set of services posted (at the current time of this writing), here are all the services I can provide. You may ask me about them either through email or Fiverr.
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        <i class="fa-solid fa-chalkboard-user"></i> <a href="https://www.wyzant.com/refer/tutor/88657982?utm_source=TREF&utm_campaign=GI&utm_content=TProfile&utm_medium=link
">Tutoring in Computer Science</a>
                                        <ul>
                                            <li>
                                                Programming langauges
                                            </li>
                                            <li>
                                                Data structures
                                            </li>
                                            <li>
                                                Web dev
                                            </li>
                                            <li>
                                                Video game programming
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <i class="fa-solid fa-gamepad"></i> Game Development
                                        <ul>

                                            <li>
                                                Unity
                                            </li>
                                            <li>
                                                Unreal Engine
                                            </li>
                                            <li>
                                                Fortnite Creative 2.0
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <i class="fa-solid fa-code"></i> Software Development
                                        <ul>
                                            <li>
                                                Windows Console and Visual Applications
                                            </li>
                                            <li>
                                                Java Applications
                                            </li></ul>
                                    </li>
                                    <li>
                                        <i class="fa-brands fa-square-js"></i> Web Development
                                        <ul>
                                            <li>
                                                Standard HTML Websites
                                            </li>
                                            <li>
                                                Dynamic Websites (with PHP or NodeJS)
                                            </li>
                                            <li>
                                                NodeJS powered apps, like with ReactJS and ApolloServer.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <i class="fa-solid fa-pencil"></i> Writing (No A.I. <i class="fa-solid fa-cow"></i><i class="fa-solid fa-poo"></i>)
                                        <ul>
                                            <li>Blurbs</li>
                                            <li>Essays</li>
                                            <li>Short Stories</li>
                                            <li>Marketing Content</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <i class="fa-solid fa-paintbrush"></i> Illustration (No A.I. <i class="fa-solid fa-cow"></i><i class="fa-solid fa-poo"></i>)
                                        <ul>
                                            <li>
                                                Custom Icons
                                            </li>
                                            <li>
                                                Simple Book Covers
                                            </li>
                                            <li>A short comicstrip</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <i class="fa-solid fa-hat-wizard"></i> Consultation
                                    </li>
                                </ul>
                            </p>
                            <p>
                                My tutoring services are offered on the <a href="https://www.wyzant.com/refer/tutor/88657982?utm_source=TREF&utm_campaign=GI&utm_content=TProfile&utm_medium=link">Wyzant</a> platform. Please read all the information regarding the website such as the terms of service and privacy policies before scheduling a tutoring session.
                            </p>
                            <p>
                                If there is a programming language, technology, or some relevant (and legitmate) service you do not see on the list, feel free to request and we can figure it out from there.
                            </p>
                        </Col>
                    </Row>
                    </Container>
                    <hr className='m-5'></hr>
                <Container>
                    <Row id="resume" dark className='p-3'>
                        <Col>
                            <h1>Resume</h1>
                            <h2>A Brief List of my Experiences</h2>
                            <div className='p-3'>
                                <p>
                                    <i class="fa-solid fa-graduation-cap"></i> Bachelor of Computer Science
                                </p>
                                <p>
                                    <i class="fa-solid fa-code"></i> Coding Experience
                                    <ul>
                                        <li>
                                            <i class="fa-brands fa-js"></i> JavaScript, TypeScript, <i class="fa-brands fa-node-js"></i> NodeJS
                                        </li>
                                        <li>
                                            <i class="fa-brands fa-html5"></i> HTML5, HTML
                                        </li>
                                        <li>
                                            <i class="fa-brands fa-css3"></i> CSS3, CSS, <i class="fa-brands fa-bootstrap"></i> Bootstrap
                                        </li>
                                        <li>
                                            <i class="fa-brands fa-php"></i> PHP, <i class="fa-brands fa-laravel"></i> Laravel
                                        </li>
                                        <li>
                                            <i class="fa-brands fa-java"></i> Java, C#
                                        </li>
                                        <li>
                                            <i class="fa-brands fa-unity"></i> Unity (Game Engine), Unreal Engine
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                    <hr className='m-5'></hr>
                <Container>
                    <Row id="portfolio" dark className='p-3'>
                        <Col>
                            <h1>Portfolio</h1>
                            <h2>A collection of ideas illuminating, projects brought to life, and dreams come true.</h2>
                            <p className='py-5'>
                                <Row>
                        <Col className='col-6'>
                            <Card className="my-2">
                                <CardBody>
                                    <CardTitle tag="h3">
                                    WZ Plumbing Services
                                    </CardTitle>
                                    <CardText className='fs-4'>
                                        A website like this is created with <i class="fa-brands fa-php"></i> PHP, <i class="fa-brands fa-js"></i> JavaScript, <i class="fa-brands fa-html5"></i> HTML, and  <i class="fa-brands fa-css3"></i> CSS.
                                    </CardText>
                                <CardImg
                                    alt="Card image cap"
                                    bottom
                                    src="images/clients/wz-snapshot.png"
                                    width="100%"
                                />
                                    <CardText>
                                        <small className="text-muted fs-5">
                                            May 2024
                                        </small>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className='col-6'>
                            <Card className="my-2">
                                <CardBody>
                                    <CardTitle tag="h3">
                                    Resonance Stories
                                    </CardTitle>
                                    <CardText className='fs-4'>
                                        This site was made with LOVE <i class="fa-regular fa-heart"></i>. And <i class="fa-brands fa-laravel"></i> Laravel. <a href="https://trianglesaloon.com/">Check out the site today</a>. And while you are at it, check out the book on Amazon. The author (me) would appreciate it!
                                    </CardText>
                                <CardImg
                                    alt="Card image cap"
                                    bottom
                                    src="images/clients/trianglesaloon-snapshot.png"
                                    width="100%"
                                />
                                    <CardText>
                                        <small className="text-muted fs-5">
                                            June 2021
                                        </small>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                            </Row>
                            </p>
                            <h3><small>Add yours to the portfolio today!</small></h3>
                        </Col>
                    </Row>
                    </Container>
                    <hr className='m-5'></hr>
                    {/* <Row id="testimonials" dark>
                        <Col>
                            <h1>Testimonials</h1>
                        </Col>
                    </Row> */}
                <Container>
                    <Row id="contact" dark className='p-3'>
                        <Col>
                            <h1>Get In Touch</h1>
                            <h2>I like emails in my inbox</h2>
                            <p>
                                For business inquiries, send an email to <a href="mailto:tony@mendozacreativeprojects.com">tony@mendozacreativeprojects.com</a>, or click the magic button below!
                            </p>
                            <p className='text-center'>
                                <a href="mailto:tony@mendozacreativeprojects.com"><Button btn-large className="btn btn-lg  p-3"><h3>Make YOUR Dream Project A Reality</h3></Button></a>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Navbar color="light" light expand="md">
                    <NavbarBrand href="/">Copyright &copy; Tony Mendoza. All Rights Reserved</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink href="#welcome">Terms & Policy</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#about">Disclaimer</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

export default App;